import axios from 'axios'
import { NotificationManager } from 'react-notifications'
import 'react-notifications/lib/notifications.css'
import i18n from '../i18n';
import { getTokenFromLocalStorage, storeTokenInLocalStorage } from './common'
import { logout } from 'src/actions/AuthentificationActions'

const STD_TIMEOUT = 600000
class HttpClient {

  client;

  constructor() {
    this.client = axios.create({
      baseURL: process.env.REACT_APP_API_BASE_URL,
      timeout: STD_TIMEOUT,
      headers: {
        'content-type': 'application/json',
        'accept': 'application/json',
      },
    });
    this.client.defaults.withCredentials = true;



  }

  refreshToken = async () => {
    try {
      const response = await this.client.post(process.env.REACT_APP_API_BASE_URL + 'auth/refresh-token');
      if (response?.status !== 200) {
        throw new Error('Erreur lors du rafraîchissement du token');
      }
      return response;
    } catch (e) {
      handleError(e)
    }
  }


  setAuthorization = () => {
    const token = getTokenFromLocalStorage()
    if (!token) {
      delete this.client.defaults.headers.common['x-access-token']

    } else {

      this.client.defaults.headers.common['x-access-token'] = token
    }

    this.client.interceptors.response.use(
      response => {
        const accessToken = response?.headers.get("x-access-token")
        if (accessToken) {
          storeTokenInLocalStorage(accessToken)
        }

        return response
      },
      async error => {
        if (error?.response?.status === 401 && error?.response?.data?.message === 'Authentification requise') {
          const response = await this.refreshToken()

          const accessToken = response?.headers?.get("x-access-token");

          if (!accessToken) {
            return Promise.reject(error);
          }

          this.client.defaults.headers.common['x-access-token'] = accessToken;
          error.config.headers['x-access-token'] = accessToken;
          return this.client(error.config);

        }
        return Promise.reject(error);

      }
    );

  }

  setContentType = (type) => {
    if (!type) {
      return
    }

    this.client.interceptors.request.use((config) => {
      config.headers['Content-Type'] = type
      return config
    })
  }

  clearAuthorization = () => {
    delete this.client.defaults.headers.common['x-access-token']
  }

  get = async (params, showNotification = true) => {
    this.setAuthorization()
    try {
      const signal = params.signal
      if (signal) delete params.signal
      const resp = await this.client.get(process.env.REACT_APP_API_BASE_URL + params.url, { params: params.data }, { signal })
      if (resp.status === 200) {
        if (showNotification && resp.data?.message) NotificationManager.success(resp.data.message, 'Notification')
        return resp.data?.data ?? resp.data
      }
      return null

    } catch (e) {
      handleError(e)
      return null
    }
  }

  post = async (params, showNotification = true) => {
    this.setAuthorization()

    try {
      const response = await this.client.post(process.env.REACT_APP_API_BASE_URL + params.url, params.data)
      if (showNotification && response.data?.message) NotificationManager.success(response.data?.message)
      return response.data
    } catch (e) {
      handleError(e)
      return null
    }
  }

  postWithFile = async (params, showNotification = true) => {
    let formData
    if (params.data instanceof FormData) {
      formData = params.data
    } else {
      formData = new FormData();
      for (let property in params.data) {
        formData.append(property, params.data[property])
      }
    }


    try {
      const response = await this.client.post(process.env.REACT_APP_API_BASE_URL + params.url, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
      if (showNotification && response.data?.message) NotificationManager.success(response.data?.message)
      return response.data
    } catch (e) {
      handleError(e)
      return null
    }
  }

  patch = async (params, showNotification = true) => {
    this.setAuthorization()
    try {
      const response = await this.client.patch(process.env.REACT_APP_API_BASE_URL + params.url, params.data)
      if (showNotification && response.data?.message) NotificationManager.success(response.data?.message)

      return response.data
    } catch (e) {
      handleError(e)
      return null
    }
  
  }
  put = async (params, showNotification = true) => {
    this.setAuthorization()
    try {
      const response = await this.client.put(process.env.REACT_APP_API_BASE_URL + params.url, params.data)
      if (showNotification && response.data?.message) NotificationManager.success(response.data?.message)

      return response.data
    } catch (e) {
      handleError(e)
      return null
    }
  }

  putWithFile = async (params, showNotification = true) => {

    let formData
    if (params.data instanceof FormData) {

      formData = params.data
    } else {

      formData = new FormData();
      for (let property in params.data) {
        formData.append(property, params.data[property])
      }
    }

    try {
      const response = await this.client.put(process.env.REACT_APP_API_BASE_URL + params.url, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
      if (showNotification && response.data?.message) NotificationManager.success(response.data?.message)
      return response.data
    } catch (e) {
      handleError(e)
      return null
    }
  }

  delete = async (params, showNotification = true) => {
    this.setAuthorization()
    try {
      const response = await this.client.delete(process.env.REACT_APP_API_BASE_URL + params.url, params.data)
      if (showNotification && response.data?.message) NotificationManager.success(response.data?.message)

      return response.data
    } catch (e) {
      handleError(e)
      return null
    }
  }

  getFile = async (params) => {
    this.setAuthorization()
    try {
      const response = await this.client.get(process.env.REACT_APP_SERVER_BASE_URL + params.url, { params: params.data, responseType: 'blob' })
      return response.data
    } catch (e) {
      return null
    }
  }
}

async function handleError(e) {

  if ((e.response?.status === 403 || e.response?.status === 401) && e.response?.data?.error?.name === "TokenExpiredError") handleLogout()

  if (Array.isArray(e.response?.data?.message)) {

    for (const message of e.response?.data?.message) {
      if (message !== 'Authentification requise') {
        NotificationManager.error(message, 'Erreur notification', 5000)
      }

    }

  } else if (e.response?.data?.message !== 'Authentification requise' && !(e.response?.status === 403 && e.config?.method === 'get')) {

    const message =  e.response?.data?.message ?? 'Une erreur s\'est survenue.'
    const translatedMessage = e.response?.data?.translatedMessageKey ? i18n.t(e.response?.data?.translatedMessageKey) : message
    NotificationManager.error(  translatedMessage, 'Erreur notification', 5000)
  }

  return e
}

async function handleLogout() {
  logout(false)
}

const httpClient = new HttpClient()

export default httpClient
