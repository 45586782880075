import HttpClient from 'src/common/HttpClient'
import { store } from 'src/app/store'

export const getAdmins = async (payload) => {
  const params = {
    url: 'compte-admins',
    data: { ...payload, limit: store.getState().pageConfig, page: payload.page ?? 0 }
  }

  const response = await HttpClient.get(params, true)
  return response
}


export const getAdmin = async (payload) => {
  const id = payload.id
  delete payload.id
  const params = {
    url: 'compte-admins/' + id,
    data: { ...payload }
  }

  const response = await HttpClient.get(params, true)
  return response
}


export const postAdmin = async (payload) => {
  const params = {
    url: 'compte-admins',
    data: { ...payload }
  }

  const response = await HttpClient.post(params, true)
  return response
}


export const updateAdmin = async (payload) => {
    const params = {
        url: 'compte-admins',
        data: { ...payload }
    }
    
    const response = await HttpClient.put(params, true)
    return response
}


export const deleteAdmin = async (payload) => {
  const params = {
    url: 'compte-admins/' + payload.id,
    data: { ...payload }
  }

  const response = await HttpClient.delete(params, true)
  return response
}

export const getAdminByUserId = async (payload) => {
    const id = payload.id
    delete payload.id
    const params = {
      url: 'compte-admins-by-user/' + id,
      data: { ...payload, init: true }
    }
    
    const response = await HttpClient.get(params, true)
    return response
}


export const updateAdminPersonalInfo = async (payload) => {
    const params = {
        url: 'compte-admins/personal-info',
        data: { ...payload }
    }
    
    const response = await HttpClient.putWithFile(params, true)
    return response
}